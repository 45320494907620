import { useMemo } from "react";

export const useExperienceControls = () => {
  const {
    avatarPositionDefault,
    keyLightIntensityDefault,
    keyLightPositionDefault,
    keyLightAngleDefault,
    keyLightPenumbraDefault,
    keyLightRotationDefault,
    fillLightIntensityDefault,
    fillLightPositionDefault,
    fillLightAngleDefault,
    fillLightPenumbraDefault,
    fillLightRotationDefault,
    backLightIntensityDefault,
    backLightPositionDefault,
    backLightAngleDefault,
    backLightRotationDefault,
    backLightPenumbraDefault,
  } = useMemo(() => {
    return {
      avatarPositionDefault: {
        value: {
          x: 1.7,
          y: 0.85,
          z: 2.75,
        },
        step: 0.05,
      },
      keyLightIntensityDefault: { value: 3, step: 1 },
      keyLightPositionDefault: {
        value: {
          x: 2,
          y: 5,
          z: 2,
        },
        step: 0.05,
      },
      keyLightRotationDefault: {
        value: {
          x: 0,
          y: 0,
          z: 0,
        },
        step: 0.05,
      },
      keyLightAngleDefault: { value: 1, step: 0.01 },
      keyLightPenumbraDefault: { value: 0.5, step: 0.01 },
      fillLightIntensityDefault: { value: 100, step: 1 },
      fillLightPositionDefault: {
        value: {
          x: 2,
          y: 5,
          z: 2,
        },
        step: 0.05,
      },
      fillLightRotationDefault: {
        value: {
          x: 0,
          y: 0,
          z: 0,
        },
        step: 0.05,
      },
      fillLightAngleDefault: { value: 1, step: 0.01 },
      fillLightPenumbraDefault: { value: 0.5, step: 0.01 },
      backLightIntensityDefault: { value: 150, step: 1 },
      backLightPositionDefault: {
        value: {
          x: -3,
          y: 2,
          z: 2,
        },
        step: 0.05,
      },
      backLightRotationDefault: {
        value: {
          x: 0,
          y: 0,
          z: 0,
        },
        step: 0.05,
      },
      backLightAngleDefault: { value: 0.6, step: 0.01 },
      backLightPenumbraDefault: { value: 0.5, step: 0.01 },
    };
  }, []);

  // Default values to return in production
  const values = {
    avatarPosition: avatarPositionDefault.value,
    fillLightPosition: fillLightPositionDefault.value,
    fillLightIntensity: fillLightIntensityDefault.value,
    fillLightAngle: fillLightAngleDefault.value,
    fillLightPenumbra: fillLightPenumbraDefault.value,
    fillLightRotation: fillLightRotationDefault.value,
    backLightPosition: backLightPositionDefault.value,
    backLightIntensity: backLightIntensityDefault.value,
    backLightAngle: backLightAngleDefault.value,
    backLightPenumbra: backLightPenumbraDefault.value,
    backLightRotation: backLightRotationDefault.value,
    keyLightIntensity: keyLightIntensityDefault.value,
    keyLightAngle: keyLightAngleDefault.value,
    keyLightPenumbra: keyLightPenumbraDefault.value,
    keyLightPosition: keyLightPositionDefault.value,
    keyLightRotation: keyLightRotationDefault.value,
  };

  return {
    avatarPosition: values.avatarPosition,
    fillLightPosition: values.fillLightPosition,
    fillLightIntensity: values.fillLightIntensity,
    fillLightAngle: values.fillLightAngle,
    fillLightPenumbra: values.fillLightPenumbra,
    fillLightRotation: values.fillLightRotation,
    backLightPosition: values.backLightPosition,
    backLightIntensity: values.backLightIntensity,
    backLightAngle: values.backLightAngle,
    backLightPenumbra: values.backLightPenumbra,
    backLightRotation: values.backLightRotation,
    keyLightIntensity: values.keyLightIntensity,
    keyLightAngle: values.keyLightAngle,
    keyLightPenumbra: values.keyLightPenumbra,
    keyLightPosition: values.keyLightPosition,
    keyLightRotation: values.keyLightRotation,
  };
};
