"use client";

import { useHydration } from "@/hooks/useHydration";
import React, { Suspense, useEffect, useState } from "react";
import * as icons from "@/icons";
import { Experience } from "@/app/newLayoutComponents/Canvas/Experience1";
import Header from "@/app/newLayoutComponents/Header/Header";

const NewLayoutPage: React.FC = () => {
  const hydrated = useHydration();

  const [time, setDate] = useState(
    new Date().toLocaleTimeString("us-US", {
      timeZone: "Europe/Prague",
    })
  );

  useEffect(() => {
    const timer = setInterval(
      () =>
        setDate(
          new Date().toLocaleTimeString("us-US", {
            timeZone: "Europe/Prague",
          })
        ),
      1000
    );
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className="max-w-screen-2xl h-svh mx-auto px-6 py-4 flex flex-col items-stretch justify-between">
      <Header className="z-10" />

      <div className="z-10 flex items-center justify-between gap-20 flex-col-reverse md:flex-row">
        <ul className="flex flex-wrap items-center justify-center w-full max-w-[unset] md:max-w-[360px] gap-2">
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.html className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              html5
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.css className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              css3
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.javascript className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              javascript
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.react className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              react
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.redux className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              redux
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.nodejs className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              nodejs
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.postgresql className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              postgresql
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.mongodb className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              mongodb
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.figma className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              figma
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.blender className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              blender
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.nextjs className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              nextjs
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.vercel className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              vercel
            </span>
          </li>
          <li className="text-white group relative flex items-center justify-center w-10 h-10 rounded-full p-0">
            <icons.strapi className="w-full h-full filter brightness-[0.3] hover:brightness-[1] hover:scale-[1.2] transition-all" />
            <span className="text-foreground absolute border border-1 bg-transparent  backdrop-blur-md [box-shadow:0_4px_30px_rgba(0,_0,_0,_0.1)] rounded-full -top-10 px-2 inset flex items-center justify-center text-center after:content-[' '] after:absolute after:top-[100%]  after:left-[50%] after:translate-x-[-50%] after:border-[5px] after:border-[var(--foreground)_transparent_transparent_transparent] invisible group-hover:visible capitalize">
              strapi
            </span>
          </li>
        </ul>

        <p className="max-w-[unset] md:max-w-[360px] text-xl font-bold">
          Self-taught software developer from Prague, Czechia, specializing in
          all stages of software development from idea to deployment and
          support. Proficient in HTML, CSS, and UX/UI design, translating
          designs into high-quality code. Skilled in bug resolution and
          front-end application consistency.
        </p>
      </div>

      <div className="h-full absolute inset-0">
        <Experience />
      </div>

      <footer className="z-10 flex justify-between items-end md:items-stretch">
        <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-40 justify-between text-gray-500">
          <div>
            <p>Prague, Czechia</p>
            <Suspense key={hydrated ? "local" : "utc"}>
              <time dateTime={new Date().toISOString()}>{time}</time>
            </Suspense>
          </div>

          <ul>
            <li>
              <a
                href="//github.com/pingpwned"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between gap-2 relative hover:text-gray-400 cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-gray-400 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-gray-400 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
              >
                GitHub{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8px"
                  height="8px"
                  viewBox="0 0 8 8"
                >
                  <path
                    fill="currentColor"
                    d="M0 0v8h8V6H7v1H1V1h1V0zm4 0l1.5 1.5L3 4l1 1l2.5-2.5L8 4V0z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="//linkedin.com/in/dmitriisavinykh"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between gap-2 relative hover:text-gray-400 cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-gray-400 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-gray-400 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]"
              >
                LinkedIn{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8px"
                  height="8px"
                  viewBox="0 0 8 8"
                >
                  <path
                    fill="currentColor"
                    d="M0 0v8h8V6H7v1H1V1h1V0zm4 0l1.5 1.5L3 4l1 1l2.5-2.5L8 4V0z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <p className="text-right font-[family-name:var(--font-leagueGothic)] text-3xl">
          2016 - {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
};

export default NewLayoutPage;
