import { useExperienceControls } from "@/app/newLayoutComponents/Canvas/useExperienceControls";
import { useControls, folder } from "leva";

export const useDevelopmentControls = () => {
  const {
    avatarPosition,
    keyLightIntensity,
    keyLightPosition,
    keyLightAngle,
    keyLightPenumbra,
    keyLightRotation,
    fillLightIntensity,
    fillLightPosition,
    fillLightAngle,
    fillLightPenumbra,
    fillLightRotation,
    backLightIntensity,
    backLightPosition,
    backLightAngle,
    backLightRotation,
    backLightPenumbra,
  } = useExperienceControls();

  const [values] = useControls(() => ({
    "Avatar Position": folder({
      avatarPosition: {
        ...avatarPosition,
      },
    }),
    "Key Light": folder({
      keyLightPosition: {
        ...keyLightPosition,
      },
      keyLightRotation: { ...keyLightRotation },
      keyLightIntensity,
      keyLightAngle,
      keyLightPenumbra,
    }),
    "Fill Light": folder({
      fillLightPosition: {
        ...fillLightPosition,
      },
      fillLightRotation: { ...fillLightRotation },
      fillLightIntensity,
      fillLightAngle,
      fillLightPenumbra,
    }),
    "Back Light": folder({
      backLightPosition: {
        ...backLightPosition,
      },
      backLightRotation: { ...backLightRotation },
      backLightIntensity,
      backLightAngle,
      backLightPenumbra,
    }),
  }));

  return {
    avatarPosition: values.avatarPosition,
    fillLightPosition: values.fillLightPosition,
    fillLightIntensity: values.fillLightIntensity,
    fillLightAngle: values.fillLightAngle,
    fillLightPenumbra: values.fillLightPenumbra,
    fillLightRotation: values.fillLightRotation,
    backLightPosition: values.backLightPosition,
    backLightIntensity: values.backLightIntensity,
    backLightAngle: values.backLightAngle,
    backLightPenumbra: values.backLightPenumbra,
    backLightRotation: values.backLightRotation,
    keyLightIntensity: values.keyLightIntensity,
  };
};
