import { motion } from "framer-motion";

export const AnimatedName = () => {
  const name = "Dmitrii-Savinykh".split("-");
  const line1 = name[0];
  const line2 = name[1];
  const line3 = "Software Developer";
  const letterVariants = {
    initial: { opacity: 0 },
    hover: (i: number) => ({
      opacity: 1,
      transition: { delay: i * 0.1 + 0.2 },
    }),
  };
  const letterVariantsInverted = {
    initial: { opacity: 1 },
    hover: (i: number) => ({
      opacity: 0,
      transition: { delay: i * 0.1 },
    }),
  };

  return (
    <div>
      <motion.h1
        initial="initial"
        whileHover="hover"
        className="overflow-hidden relative font-[family-name:var(--font-leagueGothic)] text-6xl uppercase"
      >
        <div aria-hidden>
          {line1.split("").map((l, i) => {
            return (
              <motion.span
                key={i}
                // initial={{ opacity: 1 }}
                // animate={{ opacity: 0 }}
                // transition={{ delay: i * 0.1 }}
                custom={i}
                variants={letterVariants}
              >
                {l}
              </motion.span>
            );
          })}
          <br />
          {line2.split("").map((l, i) => {
            return (
              <motion.span
                key={i}
                // initial={{ opacity: 1 }}
                // animate={{ opacity: 0 }}
                // transition={{ delay: i * 0.1 }}
                custom={i}
                variants={letterVariants}
              >
                {l}
              </motion.span>
            );
          })}
        </div>
        <div className="absolute inset-0" aria-hidden>
          {line1.split("").map((l, i) => {
            return (
              <motion.span
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: [1, 0, 1] }}
                transition={{ delay: i * 0.1 }}
                custom={i}
                variants={letterVariantsInverted}
              >
                {l}
              </motion.span>
            );
          })}
          <br />
          {line2.split("").map((l, i) => {
            return (
              <motion.span
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: [1, 0, 1] }}
                transition={{ delay: i * 0.1 }}
                custom={i}
                variants={letterVariantsInverted}
              >
                {l}
              </motion.span>
            );
          })}
        </div>
        <span className="sr-only">Dmitrii Savinykh</span>
      </motion.h1>
      <p className="text-gray-500">
        {line3.split("").map((l, i) => {
          return (
            <motion.span
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: i * 0.1 + 1 }}
            >
              {l}
            </motion.span>
          );
        })}
      </p>
    </div>
  );
};

export const HeaderHero = () => {
  return (
    <header className="flex flex-auto justify-between items-start gap-5">
      <AnimatedName />

      {/* <p className="text-gray-500 flex flex-col h-full">
        <span className="text-foreground">
          HTML/CSS/JS React.js, Typescript
          <br />
          Node.js, Next.js, Postgresql
          <br />
          Web Accessibility, SEO
        </span>
        <span>
          Strapi, Prisma, Wordpress
          <br />
          Android, iOS
        </span>
      </p> */}
    </header>
  );
};
