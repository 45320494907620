"use client";

import {
  GizmoHelper,
  GizmoViewport,
  MeshReflectorMaterial,
  OrbitControls,
  PerspectiveCamera,
} from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import dynamic from "next/dynamic";
import { Suspense, useEffect } from "react";
import { RepeatWrapping, TextureLoader, Vector2 } from "three";
import { useExperienceControls } from "@/app/newLayoutComponents/Canvas/useExperienceControls";
import { useDevelopmentControls } from "@/app/newLayoutComponents/Canvas/useDevelopmentControls";

const isDev = process.env.NODE_ENV === "development";

const DynamicModel = dynamic(
  () => import("@/components/avatar-3d").then((mod) => mod.Model),
  {
    loading: () => null,
    ssr: false,
  }
);

export const Experience = () => {
  return (
    <Suspense fallback={null}>
      <Canvas
        shadows
        camera={{
          fov: 15,
          near: 0.5,
          far: 1500,
          position: [4, 2, 5],
        }}
      >
        <Scnene />
      </Canvas>
    </Suspense>
  );
};

const Scnene = () => {
  const {
    avatarPosition,
    keyLightIntensity,
    // keyLightPosition,
    // keyLightAngle,
    // keyLightPenumbra,
    // keyLightRotation,
    fillLightIntensity,
    fillLightPosition,
    fillLightAngle,
    fillLightPenumbra,
    fillLightRotation,
    backLightIntensity,
    backLightPosition,
    backLightAngle,
    backLightRotation,
    backLightPenumbra,
  } =
    process.env.NODE_ENV === "development"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useDevelopmentControls()
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useExperienceControls();

  return (
    <>
      <PerspectiveCamera
        onUpdate={(self) => self.lookAt(0, 0.5, 0)}
        makeDefault
        fov={25}
        position={[3, 2, 5]}
      />

      <spotLight
        color={[1, 0.25, 0.7]}
        intensity={fillLightIntensity}
        angle={fillLightAngle}
        penumbra={fillLightPenumbra}
        rotation={[
          fillLightRotation.x,
          fillLightRotation.y,
          fillLightRotation.z,
        ]}
        position={[
          fillLightPosition.x,
          fillLightPosition.y,
          fillLightPosition.z,
        ]}
        castShadow
        shadow-bias={-0.0001}
      />

      <spotLight
        color={[0.14, 0.5, 1]}
        intensity={backLightIntensity}
        angle={backLightAngle}
        penumbra={backLightPenumbra}
        rotation={[
          backLightRotation.x,
          backLightRotation.y,
          backLightRotation.z,
        ]}
        position={[
          backLightPosition.x,
          backLightPosition.y,
          backLightPosition.z,
        ]}
        castShadow
        shadow-bias={-0.0001}
      />

      <ambientLight color={0xffffff} intensity={keyLightIntensity} />
      <mesh position={[avatarPosition.x, avatarPosition.y, avatarPosition.z]}>
        <DynamicModel />
      </mesh>

      <Ground />
      {isDev && (
        <>
          <Helpers />
          <OrbitControls />
        </>
      )}
    </>
  );
};

const Ground = () => {
  const [roughness, normal] = useLoader(TextureLoader, [
    "/textures/roughness.jpg",
    "/textures/normal.jpg",
  ]);

  useEffect(() => {
    [normal, roughness].forEach((texture) => {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.repeat.set(5, 5);
    });
  }, [roughness, normal]);
  return (
    <>
      <mesh
        position={[0, -5, 0]}
        rotation={[0, 0.45, 0]}
        castShadow
        receiveShadow
      >
        <planeGeometry args={[30, 30]} />

        <MeshReflectorMaterial
          normalMap={normal}
          normalScale={new Vector2(0.15, 0.15)}
          roughnessMap={roughness}
          dithering={true}
          color={[0.015, 0.015, 0.015]}
          roughness={0.7}
          blur={[100, 40]} // Blur ground reflections (width, heigt), 0 skips blur
          mixBlur={2} // How much blur mixes with surface roughness (default = 1)
          mixStrength={20} // Strength of the reflections
          mixContrast={1} // Contrast of the reflections
          resolution={1024} // Off-buffer resolution, lower=faster, higher=better quality, slower
          mirror={0} // Mirror environment, 0 = texture colors, 1 = pick up env colors
          depthScale={0.1} // Scale the depth factor (0 = no depth, default = 0)
          minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
          maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
          depthToBlurRatioBias={0.1} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
          reflectorOffset={-1} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
        />
      </mesh>

      <mesh
        position={[0, 0, 0]}
        rotation={[-Math.PI * 0.5, 0, 0]}
        castShadow
        receiveShadow
      >
        <planeGeometry args={[30, 30]} />

        <MeshReflectorMaterial
          normalMap={normal}
          normalScale={new Vector2(0.15, 0.15)}
          roughnessMap={roughness}
          dithering={true}
          color={[0.015, 0.015, 0.015]}
          roughness={0.7}
          blur={[100, 40]} // Blur ground reflections (width, heigt), 0 skips blur
          mixBlur={2} // How much blur mixes with surface roughness (default = 1)
          mixStrength={20} // Strength of the reflections
          mixContrast={1} // Contrast of the reflections
          resolution={1024} // Off-buffer resolution, lower=faster, higher=better quality, slower
          mirror={0} // Mirror environment, 0 = texture colors, 1 = pick up env colors
          depthScale={0.1} // Scale the depth factor (0 = no depth, default = 0)
          minDepthThreshold={0.9} // Lower edge for the depthTexture interpolation (default = 0)
          maxDepthThreshold={1} // Upper edge for the depthTexture interpolation (default = 0)
          depthToBlurRatioBias={0.1} // Adds a bias factor to the depthTexture before calculating the blur amount [blurFactor = blurTexture * (depthTexture + bias)]. It accepts values between 0 and 1, default is 0.25. An amount > 0 of bias makes sure that the blurTexture is not too sharp because of the multiplication with the depthTexture
          reflectorOffset={0.1} // Offsets the virtual camera that projects the reflection. Useful when the reflective surface is some distance from the object's origin (default = 0)
        />
      </mesh>
    </>
  );
};

const Helpers = () => {
  return (
    <>
      <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
        <GizmoViewport />
      </GizmoHelper>

      <gridHelper args={[20, 20, 0xff22aa, 0x55ccff]} />
      <axesHelper args={[10]} />
    </>
  );
};
