export { default as css } from "./css.svg";
export { default as blender } from "./blender.svg";
export { default as html } from "./html.svg";
export { default as javascript } from "./javascript.svg";
export { default as figma } from "./figma.svg";
export { default as mongodb } from "./mongodb.svg";
export { default as nextjs } from "./nextjs.svg";
export { default as nodejs } from "./nodejs.svg";
export { default as postgresql } from "./postgresql.svg";
export { default as react } from "./react.svg";
export { default as redux } from "./redux.svg";
export { default as strapi } from "./strapi.svg";
export { default as vercel } from "./vercel.svg";
export { default as actum } from "./actum.svg";
export { default as hellIT } from "./hellIT.svg";
export { default as satoshilabs } from "./satoshilabs.svg";
export { default as externalLink } from "./external-link.svg";
export { default as githubMark } from "./github-mark.svg";
