import { HeaderHero } from "@/app/newLayoutComponents/Header/Hero";
import { forwardRef } from "react";
// import { HeaderNav } from "@/app/newLayoutComponents/Header/Nav";

const Header = forwardRef(
  (
    props: React.ComponentProps<"header">,
    ref: React.ForwardedRef<HTMLHeadElement>
  ) => {
    return (
      <header ref={ref} {...props}>
        <HeaderHero />
        {/* <HeaderNav /> */}
      </header>
    );
  }
);

Header.displayName = "Header";

export default Header;
